import React, {useState} from 'react';
import { Link, Icon, Input, Paragraph } from 'operation-diameter';

import Error from './components/Error';
import Form from './components/Form';
import NavGroup from './components/NavGroup';
import NavItem from './components/NavItem';
import NoteAndError from './components/NoteAndError';
import NotFoundPage from './components/NotFoundPage';
import PageError from './components/PageError';

import styles from './App.module.css';

const App = () => {
  const [displayValue, setValue] = useState('initial value');
  
  return (
    <div className={styles.app}>
      <h1>Shared components</h1>
      <h2>NavGroup</h2>
      <div className={styles.navigation}>
        <NavItem to='/' label='Summary' onClick={() => console.log('Click')} />
        <NavGroup header='Header'>
          <NavItem
            to='/overview'
            label='Overview'
            onClick={() => console.log('Click')}
          />
          <NavItem
            to='/loans'
            label='Loans'
            onClick={() => console.log('Click')}
            disabled
          />
          <NavItem
            to='/other-stuff'
            label='Other stuff'
            onClick={() => console.log('Click')}
          />
        </NavGroup>
        <NavGroup header='Header 2'>
          <NavItem
            to='/accounts'
            label={
              <>
                <Icon icon='urgent' title='icon' fill='--color-white' />
                <Paragraph additionalClassnames={styles.navItemText}>Accounts</Paragraph>
              </>
            }
            onClick={() => console.log('Click')}
          />
        </NavGroup>
        <NavItem
          to='/support'
          label='Support'
          onClick={() => console.log('Click')}
        />
        <NavItem
          to='/signout'
          label='Sign out'
          onClick={() => console.log('Click')}
        />
      </div>
      <h2>Nav Icons</h2>
      <div className={styles.navigation}>
        <NavItem to='/' label='Summary' onClick={() => console.log('Click')} />
        <NavGroup header='FlexiPay'>
          <NavItem
            to='/pay'
            iconName='call-made'
            label='Pay'
            onClick={() => console.log('Click')}
          />
          <NavItem
            to='/payment-history'
            iconName='history'
            label='Payment History'
            onClick={() => console.log('Click')}
          />
          <NavItem
            to='/transactions'
            iconName='transactions'
            label='Transactions'
            onClick={() => console.log('Click')}
          />
          <NavItem
            to='/Repayments'
            iconName='calendar-today'
            label='Repayments'
            onClick={() => console.log('Click')}
          />
          <NavItem
            to='/card'
            iconName='credit-card'
            label='Card'
            onClick={() => console.log('Click')}
          />
          <NavItem
            to='/request-payment'
            iconName='call-received'
            label='Get Paid'
            onClick={() => console.log('Click')}
          />
          <NavItem
            to='/flexipay-rewards'
            iconName='cashback'
            label='Cashback'
            onClick={() => console.log('Click')}
          />
          <NavItem
            to='/refer-business'
            iconName='group-add'
            label='Refer business'
            onClick={() => console.log('Click')}
          />
          <NavItem
            to='/Repayments'
            iconName='group-add'
            label='Disabled Icon'
            disabled
            onClick={() => console.log('Click')}
          />
        </NavGroup>
        <NavGroup header='Business Loans'>
          <NavItem
            to='/overview'
            iconName='view-agenda'
            label='Overview'
            onClick={() => console.log('Click')}
          />
        </NavGroup>
        <NavItem
          to='/support'
          label='Support'
          onClick={() => console.log('Click')}
        />
        <NavItem
          to='/signout'
          label='Sign out'
          onClick={() => console.log('Click')}
        />
      </div>
      <h2>Error</h2>
      <Error
        message='Please refresh the page, or try again later.'
        showCustomerSupport
        showCta
      />
      <h2>Form & Input (Deprecated)</h2>
      <p>
        The form is simply a wrapper with some built-in methods. The following
        input is wrapped in a form.
      </p>
      <Form onSubmit={() => {}}>
        <Input label='Input' name='input' value={displayValue} onChange={(e) => setValue(e.target.value)} />
      </Form>
      <h2>NoteAndError</h2>
      <NoteAndError
        note='This is a note'
        error={{ message: 'This is an error' }}
      />
      <h2>Not Found Page</h2>
      <NotFoundPage>
        <Link styledAsButton>Refresh page</Link>
      </NotFoundPage>
      <h2>Page Error</h2>
      <PageError message='Please try again'>
        <Link styledAsButton>Refresh page</Link>
      </PageError>
    </div>
  );
};

export default App;
